import { Milestone } from '../../Models/Milestone';
import { IInfrastructure, RefInfrastructure } from '../../Models/IInfrastructure';
import { IPackageWithSaving, IRawPackage, IRefPackage } from '../../Models/IRefPackage';
import { Observable, of } from 'rxjs';
import { Clientdetails } from '../../Models/Clientdetails';
import { Constant } from '../../shared/Constant';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IInfrastructureBoq } from 'src/app/Models/IInfrastructureBoq';
import { UserView } from 'src/app/Models/UserView';
import { IInfrastructureDetail, InfrastructureDetail } from '../../pages/graph/IInfrastructureDetail';
import { IPraxisAPIResult } from './IPraxisAPIResult';
import { catchError, map, tap } from 'rxjs/operators';
import {
  ICostOverTime,
  IInfraProjectBoq,
  IInfrastructureCostOverWeek,
  InfrastructureWithAllBoqs,
  IProjectSummaryReport,
  IRefBoq
} from './IProjectSummaryReport';
import * as _ from 'lodash';
import { IBOQCode } from '../../Models/IBoqHeader';
import { ICostOfEachDiscipline } from '../../Models/ICostOfEachDiscipline';
import { BoqCodeToCompare, CompareResult, ICompareBoqResultV3Raw } from '../../Models/compare-boq-result';
import { IBoqToCompare, IBoqToCompareSentFromServer } from '../../Models/IBoqToCompare';
import { IOptionSummary } from '../../Models/OptionList';
import { IGetProjectSnapshotResult } from '../../Models/IGetProjectSnapshotResult';
import { IPackagesInfraModel } from '../../Models/IPackagesInfraModel';
import { environment } from '../../../environments/environment';
import { EBoqClassification } from './classificationEnum';
import { toInteger } from 'lodash';
import { AuthGaurdGuard } from 'src/app/helpers/Auth-gurad/auth-gaurd.guard';
import { BoqResponse } from 'src/app/Models/boq-detail';
import { UserRole } from 'src/app/Models/user-role';
import { ProjectUsersList } from 'src/app/Models/project-users-list';
import { TimelineResponse } from 'src/app/pages/graph/timeline-card/timeline-card.type';

export const PROJECT_SUMMARY_ERROR_MESSAGE = 'Should have exactly 1 Project returned';

interface DataSet<T> {
  Table: T[];
}

export enum EPackageAcceptanceStatus {
  Review,
  Accepted,
  Declined
}

export interface IRefProjectSnapshotBoq {
  projectSnapshotBoqName: string;
  boqClassificationInd: EBoqClassification;
  createdDt: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) {
  }

  private static isDataSet<T>(parse: unknown): parse is DataSet<T> {
    return !(
      !_.isObject(parse) ||
      _.isNil(_.get(parse, 'Table')) ||
      !_.isArray((parse as DataSet<T>).Table));
  }

  getDashboard<T>(id: number): Observable<IPraxisAPIResult<{ client: number, project: number }>> {
    return this.http.get<IPraxisAPIResult<{ client: number, project: number }>>(Constant.webapi + 'Admin/Dashboard?id=' + id).pipe();
  }

  getProject<T>(id: number): Observable<[]> {
    //todo get type
    return this.http.get<[]>(Constant.webapi + 'Admin/GetProjects?id=' + id).pipe();
  }

  getClient<T>(id: number): Observable<T> {
    return this.http.get<T>(Constant.webapi + 'Admin/GetClients?id=' + id).pipe();
  }

  addClient<T>(clientdata: Clientdetails): Observable<T> {
    return this.http.post<T>(Constant.webapi + 'Admin/ManageClient', clientdata).pipe();
  }

  getClientData<T>(id: number): Observable<T> {
    return this.http.get<T>(Constant.webapi + 'Admin/ManageClient?clientId=' + id).pipe();
  }

  addProject(projectData: FormData): Observable<object> {
    return this.http.post(Constant.webapi + 'Admin/ManageProject', projectData).pipe();
  }

  updateRate(id: number | string , projectData: FormData): Observable<object> {
    return this.http.put(Constant.webapi + `Admin/ManageProject/{id}`, projectData, {
      observe: 'body'
    }).pipe();
  }

  updateProject(projectId: string, projectData: FormData): Observable<object> {
    return this.http.put(Constant.webapi + `projects/${projectId}/ManageProject`, projectData, {
      observe: 'body'
    }).pipe();
  }

  getProjectData<T>(id: number): Observable<T> {
    return this.http.get<T>(Constant.webapi + `projects/${id}/ManageProject`).pipe();
  }

  GetPackagesInfra(projectId: number): Observable<IPraxisAPIResult<IPackagesInfraModel[]>> {
    return this.http
      .get<IPraxisAPIResult<IPackagesInfraModel[]>>(
        Constant.webapi + `projects/${projectId}/GetPackagesInfra`);
  }

  addPackage<T>(packageDetails: IRefPackage): Observable<T> {
    // return of({
    //   succeeded: true,
    //   data: {
    //     packageNum: 999
    //   }
    // } as any);
    return this.http.post<T>(Constant.webapi + `projects/${packageDetails.projectNum}/ManagePackages`, packageDetails).pipe();
  }

  public addInfrastructure(projectId: number | string, infraDetails: IInfrastructure): Observable<unknown> {
    return this.http.post(Constant.webapi + `projects/${projectId}/ManageInfrastructure`, infraDetails);
  }

  getClientProject<T>(id: number): Observable<T> {
    return this.http.get<T>(Constant.webapi + 'Admin/GetClientProjects?clientid=' + id).pipe();
  }

  getMileStoneData<T>(id: number | string): Observable<T> {
    return this.http.get<T>(Constant.webapi + `projects/${id}/GetMilestones`).pipe();
  }

  addMilestone<T>(milestoneDetails: Milestone): Observable<T> {
    return this.http.post<T>(Constant.webapi + `projects/${milestoneDetails.projectNum}/ManageMilestone`, milestoneDetails).pipe();
  }

  getRMSstandardBoq<T>(): Observable<T> {
    return this.http.get<T>(Constant.webapi + 'Admin/GetTemplateLevelBOQCodeList').pipe();
  }

  getProjectBOQNew<T>(projid: number): Observable<T> {
    return this.http.get<T>(Constant.webapi + `projects/${projid}/GetProjectBOQCodeList`).pipe();
  }

  addProjectBoq<T>(projectId: number | string, newAttribute: object): Observable<T> {
    return this.http.post<T>(Constant.webapi + `projects/${projectId}/ManageProjectBOQ`, newAttribute).pipe();
  }

  deleteprojectboq<T>(projectId: number | string, id: number): Observable<T> {
    return this.http.post<T>(Constant.webapi + `projects/${projectId}/DeleteProjectBOQ/${id}`,{}).pipe();
  }

  // Page 3
  getBoqList(projectId: number | string, infrastructureNum: number | string): Observable<IPraxisAPIResult<InfrastructureWithAllBoqs>> {
    return this.transformResponseData<IInfraProjectBoq, InfrastructureWithAllBoqs>(
      Constant.webapi + `projects/${projectId}/GetInfrastructureBOQ?InfrastructureId=` + infrastructureNum, i => {
        return new InfrastructureWithAllBoqs(i);
      });
  }

  public getOptionDetailGraphData(projectId: number | string, packageNum: number): Observable<IPraxisAPIResult<InfrastructureWithAllBoqs[]>> {
    return this.transformResponseData<IInfraProjectBoq[], InfrastructureWithAllBoqs[]>(
      Constant.webapi + `projects/${projectId}/OptionPackageBOQs/` + packageNum, infrastructures => {
        return infrastructures.map(i => {
          return new InfrastructureWithAllBoqs(i);
        });
      });
  }

  getBoqWithAtLeast1CodeWithQuantity(projectId: number | string, infrastructureId: number): Observable<IPraxisAPIResult<IBoqToCompare[]>> {
    //"{\"Table\":[{\"boqNum\":210,\"boqClassificationInd\":2,\"boqName\":\"CALC-1\",\"boqDesc\":\"CALC-1\"}]}"
    return this.http.get<string>(
      Constant.webapi + `projects/${projectId}/GetBoqWithAtLeast1CodeWithQuantity?InfrastructureId=` + infrastructureId).pipe(map(value => {
        return this.parseResultAsDataset<IBoqToCompareSentFromServer, IBoqToCompare>(value, v => ({
          ...v,
          milestoneName: ''
        } as IBoqToCompare),false);
      }));
  }


  getBoqCodeToCompare(projectNum, infrastructureId: number): Observable<IPraxisAPIResult<BoqCodeToCompare[]>> {
    return this.getCodeToCompare(
      Constant.webapi + `projects/${projectNum}/GetBoqCompareSummaryReportV2?InfrastructureId=` + infrastructureId);
  }

  public getOptionPackages(projectNum: number | string): Observable<IPraxisAPIResult<IPackageWithSaving[]>> {

    return this.http.get<string>(`${Constant.webapi}projects/${projectNum}/GetOptionPackages`).pipe(map(result => {
      return this.parseResultAsDataset<IRawPackage, IPackageWithSaving>(result, v => {
        return {
          packageOptionStatusInd: v.package_option_status_ind,
          packageNum: v.package_num,
          packageName: v.package_name,
          estimatedSaving: v.estimated_savings_amt,
          packageCd: v.package_cd,
          projectNum: v.project_num,
          createdBy: v.created_by,
          departures: null,
          packageClassificationInd: v.package_classification_ind,
          packageDesc: v.package_desc,
          createdDt: v.created_dt,
          statusInd: v.status_ind
        } as IPackageWithSaving;
      });
    }));
  }

  public addInfraBOQ(addboqDeatils: IInfrastructureBoq): Observable<IPraxisAPIResult<IRefBoq>> {
    return this.http.post<IPraxisAPIResult<IRefBoq>>(Constant.webapi + `projects/${addboqDeatils.projectNum}/ManageInfrastructureBOQ`, addboqDeatils).pipe();
  }

  public addInfraBOQImport(projectNum: number | string, data: any): Observable<IPraxisAPIResult<IRefBoq>> {
    return this.http.post<IPraxisAPIResult<IRefBoq>>(Constant.webapi + `projects/${projectNum}/ManageInfrastructureBOQImport`, data).pipe();
  }

  Editboqlink<T>(projectId: string | number, editboqlink: object): Observable<T> {
    return this.http.post<T>(Constant.webapi + `projects/${projectId}/ManageBOQCode`, editboqlink).pipe();
  }

  getProjectByClient<T>(clientId: number): Observable<T> {
    return this.http.get<T>(Constant.webapi + 'Admin/GetProjectsByClient?clientid=' + clientId).pipe();
  }

  getInfrastructureSummaryReport(projectNum: number): Observable<IPraxisAPIResult<InfrastructureDetail[]>> {
    return this.http.get<IPraxisAPIResult<IInfrastructureDetail[]>>(
      Constant.webapi + `projects/${projectNum}/GetInfrastructureSummaryReport`).pipe(map(res => {
        if (!res.succeeded) {
          res.data = [];
        }
        return {
          data: res.data.map(v => new InfrastructureDetail(v)),
          succeeded: res.succeeded,
          message: res.message
        };
      }));
  }

  public getInfrastructureBOQ(projectId: number | string, boqNum: number | string): Observable<IPraxisAPIResult<IRefBoq>> {
    return this.http.get<IPraxisAPIResult<IRefBoq>>(
      `${Constant.webapi}projects/${projectId}/InfrastructureBOQ?boqNum=${boqNum}`
    );
  }

  getCostOfEachDiscipline(projectNumber: number): Observable<IPraxisAPIResult<ICostOfEachDiscipline[]>> {
    return this.http.get<IPraxisAPIResult<ICostOfEachDiscipline[]>>(Constant.webapi + `projects/${projectNumber}/CostOfDiscipline`);
  }


  public getInfrastructureCostOverWeek(projectNum: number): Observable<IPraxisAPIResult<IInfrastructureCostOverWeek>> {
    return this.http
      .get<IPraxisAPIResult<IInfrastructureCostOverWeek>>(Constant.webapi + `projects/${projectNum}/GetInfrastructureCostOverWeek`)
      .pipe(tap(({ data: infrastructureCost, succeeded, message }) => {
        //  assert that the data have all the week
        if (!succeeded) {
          throw new Error(`Fail to get Infrastructure Cost over week: ${message}`);
        }

        const weekOfEachInfrastructure = new Map<string, number[]>();

        /*If there is no infrastructure, would not do any checking*/
        if (Object.keys(weekOfEachInfrastructure).length === 0) {
          return;
        }

        for (const infrastructureNumber in infrastructureCost) {
          if (infrastructureCost.hasOwnProperty(infrastructureNumber)) {
            const costOverTime = infrastructureCost[infrastructureNumber];
            weekOfEachInfrastructure.set(infrastructureNumber, costOverTime.map(c => c.weekNum));
          }
        }

        Array.from(weekOfEachInfrastructure.values()).every((current, index, array) => {
          if (index === 0) {
            return true;
          }

          const previous = array[index - 1];
          return _.isEqual(_.sortBy(previous), _.sortBy(current));
        });


      }));
  }

  public getProjectCostOverWeek(projectNum: number): Observable<IPraxisAPIResult<ICostOverTime[]>> {
    return this.http
      .get<IPraxisAPIResult<ICostOverTime[]>>(Constant.webapi + `projects/${projectNum}/GetInfrastructureCostOverWeekV2?projectNum`);

  }

  uploadFile<T>(projectId: string | number, formData: FormData): Observable<T> {
    return this.http.put<T>(Constant.webapi + `admin/ManageProjectRates/${projectId}`, formData).pipe();
  }

  // Upload before Accept/Decline option
  uploadFileBeforeAccept<T>(projectId: string | number, formData: FormData): Observable<T> {
    return this.http.put<T>(Constant.webapi + `admin/InitialManageProjectRates/${projectId}`, formData).pipe();
  }
  
  uploadPDFile<T>(projectId: string | number, formData: FormData): Observable<T> {
    return this.http.post<T>(Constant.webapi + `projects/${projectId}/ManageBoqUploadFile`, formData).pipe();
  }

  deleteFile<T>(projectId: string | number, boqNum: number): Observable<T> {
    return this.http.delete<T>(Constant.webapi + `projects/${projectId}/DeleteBoqUploadFile/${boqNum}`).pipe();
  }

  downloadFile(src: string) {
    return this.http.get(src, { responseType: 'blob' }).pipe();
  }


  showFile(data: any, fileName: string = null) {
    const downloadedFile = new Blob([data], { type: data.type });
    if (!fileName)
      window.open(URL.createObjectURL(downloadedFile));
    else {
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(downloadedFile);
      //if ()
      downloadLink.setAttribute('download', fileName);
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }

  }

 

  getInfrastructureBOQCodeList(boqNum: number, projectNum: number): Observable<IBOQCode[]> {
    return this.http.get<string>(Constant.webapi + `projects/${projectNum}/GetInfrastructureBOQCodeList?boq_num=` + boqNum).pipe(map(v => {
      const table1 = JSON.parse(v);
      if (table1.Table1) {
        return table1.Table1;
      }
      throw new Error(`Missing key Table 1 in GetInfrastructureBOQCodeList return result:\n${v}`);
    }));
  }

  getPdfFile<T>(projectId: number | string, guidId: any): Observable<T> {
    return this.http.get<T>(Constant.webapi + `projects/${projectId}/GetBoqPDF?document_guid=` + guidId).pipe();
  }

  // Accept/Decline option
  public changePackageAcceptanceStatus(projectId: number | string,
    packageNum: number | string, optionStatus = EPackageAcceptanceStatus): Observable<IPraxisAPIResult<number>> {
    return this.http.post<IPraxisAPIResult<number>>(`${Constant.webapi}projects/${projectId}/ChangePackageAcceptanceStatus/${packageNum}`, {
      optionStatus
    });
  }

  updateQuantities(projectId: number | string, boqNum: string | number, data: FormData): Observable<any> {
    return this.http.put(Constant.webapi + `projects/${projectId}/boq/${boqNum}/infrastructure/quantities`, data, {});
  }

  importQuantities(projectId: number | string, boqNum: string | number,importName :string): Observable<any> {
    return this.http.put(Constant.webapi + `projects/${projectId}/boq/${boqNum}/infrastructure/import-quantities?import=${importName}`,{},{});
  }

  importQuantitiesDrainage(projectId: number | string, importName :string): Observable<any> {
    return this.http.put(Constant.webapi + `projects/${projectId}/import-quantities-drainage?import=${importName}`,{},{});
  }

  getUsers<T>(): Observable<T> {
    return this.http.get<T>(Constant.webapi + 'accounts/users').pipe();
  }

  getUserRoles(): Observable<UserRole[]> {
    return this.http.get<UserRole[]>(Constant.webapi + 'accounts/user-roles').pipe();
  }

  getUserProjectUsers(projectid : number | string): Observable<ProjectUsersList> {
    return this.http.get<ProjectUsersList>(Constant.webapi + `accounts/project-users-roles/${projectid}`).pipe();
  }

  updateProjectUser(projectId: Number | string, isWriter: boolean, userId: number)
  {
    return this.http.put(Constant.webapi + `accounts/project-users-roles`,{ projectId, isWriter, userId}).pipe();
  }
  
  deleteProjectUser(projectId: Number | string,  userId: number)
  {
    return this.http.delete(Constant.webapi + `accounts/project-users-roles/${projectId}/${userId}`).pipe();
  }

  updateUser<T>(userId: number, user: UserView): Observable<T> {
    return this.http.put<T>(`${Constant.webapi}accounts/users/${userId}`, user).pipe();
  }

  createUser<T>(user: UserView): Observable<T> {
    return this.http.post<T>(`${Constant.webapi}accounts/users`, user).pipe();
  }

  exportBoq(projectId) {
    return this.http.get(`${Constant.webapi}projects/${projectId}/download`, {
      responseType: 'blob',
    });
  }

  exportInfrastructureBoq(boqId, projectId) {
    return this.http.get(`${Constant.webapi}projects/${projectId}/infrastructure/boq/${boqId}/download`, {
      responseType: 'blob',
    });
  }

  exportSnapshotBoq(projectNum) {
    return this.http.get(`${Constant.webapi}projects/${projectNum}/project-snapshot/boq/download`, {
      responseType: 'blob',
    });
  }

  exportBoqCompareReport(projectId: number | string, infrastructureId, filter) {
    return this.http.post(`${Constant.webapi}projects/${projectId}/infrastructure/${infrastructureId}/report/download`, filter, {
      responseType: 'blob',
    });
  }

  private parseResultAsDataset<TInput = unknown, TOutput = TInput>(
    value: string, mapperFn: (v: TInput) => TOutput, allowEmptyDataset = false, parseJson= true): IPraxisAPIResult<TOutput[]> {
   
    let parse  = JSON.parse(value);
    if (AdminService.isDataSet<TInput>(parse)) {
      console.log(value);
      return {
        succeeded: true,
        message: '',
        data: parse.Table.map(v => {
          return mapperFn(v);
        })
      };
    } else if (allowEmptyDataset && _.isEmpty(parse) && _.isObject(parse)) {
      return {
        succeeded: true,
        message: '',
        data: []
      };
    } else {
      return {
        succeeded: false,
        message: `The result return does not match expected format: ${value}`,
        data: []
      };
    }
  }

  private getCodeToCompare(url: string): Observable<IPraxisAPIResult<BoqCodeToCompare[]>> {
    return this.http.get<string>(
      url).pipe(map(res => {
        return this.parseResultAsDataset<ICompareBoqResultV3Raw, BoqCodeToCompare>(res, v => new BoqCodeToCompare(v), true);
      }));
  }

  public getProjectOptionSummary(projectNum: number | string): Observable<IPraxisAPIResult<IOptionSummary>> {
    return this.http.get<IPraxisAPIResult<IOptionSummary>>(`${Constant.webapi}projects/${projectNum}/ProjectOptionSummary`);
  }

  public getOptionInfrastructures(projectId: number | string, packageNum: number | string): Observable<IPraxisAPIResult<InfrastructureDetail[]>> {
    return this.http.get<IPraxisAPIResult<IInfrastructureDetail[]>>(
      `${Constant.webapi}projects/${projectId}/GetOptionInfrastructures/${packageNum}`)
      .pipe(map(res => {
        if (!res.succeeded) {
          res.data = [];
        }
        return {
          succeeded: true,
          message: res.message,
          data: res.data.map(v => new InfrastructureDetail(v))
        };
      }));
  }


  public setPreferredInfrastructure(projectId: number, infrastructureNum: number): Observable<IPraxisAPIResult<number>> {
    return this.http.post<IPraxisAPIResult<number>>(`${Constant.webapi}projects/${projectId}/SetPreferredInfrastructure/${infrastructureNum}`, {});
  }

  private transformResponseData<TInput, TOutput>(url: string, transformer: (i: TInput) => TOutput) {
    return this.http.get<IPraxisAPIResult<TInput>>(url).pipe(map(res => {
      if (!res.succeeded) {
        return {
          data: null,
          succeeded: res.succeeded,
          message: res.message
        } as IPraxisAPIResult<TOutput>;
      }

      const currentInfrastructure = transformer(res.data);

      return {
        data: currentInfrastructure,
        succeeded: res.succeeded,
        message: res.message
      } as IPraxisAPIResult<TOutput>;
    }));
  }

  public compareOptionQuantity(projectId: number | string, packageNum: number | string): Observable<BoqResponse[]> {
    return this.http.get<BoqResponse[]>(
      `${Constant.webapi}projects/${projectId}/CompareOptionQuantity/${packageNum}`);
  }

  public getImageUrl(projectId: number | string, packageNum: number): Observable<any> {
    return this.http.get<IPraxisAPIResult<string | null>>(`${Constant.webapi}projects/${projectId}/GetUploadedImage/${packageNum}`)
      .pipe(map(v => {

        return v;
      }));
  }

  public ImageUrl(projectId: number | string, boqNumber: number | string, fileName: string) {
    return `${Constant.webapi}projects/${projectId}/BoqImageUrl/${boqNumber}?fileName=${fileName}`;
  }

  processBoqPost<T>(projectData: any, projectId: number): Observable<T> {
    projectData.projectId = toInteger(projectId);
    return this.http.post<T>(Constant.webapi + 'excelMacro/process-boq-excel', projectData).pipe();
  }

  exportPvfFile<T>(projectId: string): Observable<T> {
    return this.http.get<T>(Constant.webapi + 'excelMacro/export-pvf-file?projectId=' + projectId).pipe();
  }

  runAllMacros<T>(projectId: string): Observable<T> {
    return this.http.get<T>(Constant.webapi + 'excelMacro/run-all-macros?projectId=' + projectId).pipe();
  }
 
  getTimeline<T>(projectId: number): Observable<TimelineResponse> {
    return this.http.get<TimelineResponse>(Constant.webapi + `projects/${projectId}/timeline`).pipe();
  }

}
